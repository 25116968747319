<template>
    <div class="group-download">
        <div class="fly-breadcrumb">
            <Breadcrumb >
                <BreadcrumbItem to="/">主界面</BreadcrumbItem>
                <BreadcrumbItem :to="'/template/'+b_group.id">{{'分组-'+b_group.name}}</BreadcrumbItem>
                <BreadcrumbItem>下载</BreadcrumbItem>
            </Breadcrumb>
        </div>

        <Button class="group-download-btn" type="primary" 
            icon="md-download">{{'下载分组-'+b_group.name}}</Button>
        <div ref="qrcode" class="group-download-qrcode"></div>
    </div>
</template>

<script>
export default {
    data(){
        let response = $.ajax({
            url: "/group/getById/"+this.$route.params.groupId,
            async: false,
            type: 'POST'
        });
        let res = JSON.parse(response.responseText);
        return {
            b_group:res
        }
    },
    mounted(){
        let url = location.protocol+'//'+location.host+'/group/download/'+this.$route.params.groupId;
        new QRCode(this.$refs.qrcode, {
            text: JSON.stringify({
                type:'group',
                url:url
            }),
            width: 256,
            height: 256,
            colorDark : "#f60",
            colorLight : "#FFF",
            correctLevel : 1 
        });
    }
}
</script>

<style scoped>
.group-download{
    text-align: center;
    width: 100%;
    height: 100%;
}

.group-download-btn{
    width:300px;
    margin:50px auto;
}

.group-download-qrcode{
    width: 256px;
    height: 256px;
    margin: 50px auto;
}
</style>
